/* Reset styles */

@import './variable';
*{
    box-sizing: border-box;
}
a, p, h1, h2, h3, h4, h5, h6, div, section, article, nav, ul, li, table, tr, td, th, body, span, label, ol{
    font-family: $font-family;    
}
a, p, div, section, td, nav, ul, li{
    font-size: $font-size;
}
a, a:link, a:hover, a:visited, a:focus, a:active{
    font-size: 14px;
    text-decoration: none;
    font-weight: 400;
    cursor: pointer;
}

h1{
    font-size: 26px;
    font-weight: 900;
}
h2{
    font-size: 24px;
    font-weight: 700;
}
h3{
    font-size: 22px;
    font-weight: 500;
}
h4{
    font-size: 20px;
    font-weight: 400;
}
h5{
    font-size: 18px;
    font-weight: 400;
}
h6{
    font-size: 16px;
    font-weight: 400;
}
.p0{
    padding: 0;
}
.m0{
    margin: 0;
}
.pl20{ padding-left: 20px;}