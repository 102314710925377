@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$font-family: 'Roboto', sans-serif;
$font-weight: 400;
$font-size: 14px;
$line-height: 16px;
$letter-spacing: normal;
$bg: #F2F6FC;



// #4b5a71 - F2F6FC

// Table Config
$tbl_title_fs: 16px;
$tbl_title_fc: #000000;
$tbl_title_fw: 700;

$tbl_header_bg: #EFEFEF;
$tbl_header_fs: 16px;
$tbl_header_fc: #4C4C4C;
$tbl_header_fw: 500;

$tbl_cont_fs:14px;
$tbl_cont_fc: #191919;
$tbl_cont_fw: 400;

$tbl_roundbtn_fc:#0066CC;
$tbl_roundbtn_fs: 16px;
$tbl_roundbtn_fw: 500; 
$tbl_roundbtn_bdr: #BABABA;

// applobby
$border-radius-4: 4px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$green: #198754;
$blue: #0066CC;
$light-blue: #d2e9ff;
$dark-blue: #597195;
$grey: #8A8A8A;
$grey1: #737373;
$light-grey: #D3D3D3;
$dark-grey: #BABABA;
$black: #333333;
$dark-black: #191919;
$red: #ff1111;
$mt-6: 6px;
$pt-8: 8px;
$pb-9: 9px;
$pl-24: 24px;
$pr-24: 24px;
$green: #198754;
$white: #FFFFFF;

//Button
$btn_secondary_fc: #0066CC;
$btn_secondary_bg: #FAFAFA;
$btn_secondary_fs: 16px;
$btn_secondary_fw: 500;
$btn_secondary_bdr: #BABABA;

$btn_primary_fc: #FFFFFF;
$btn_primary_bg: #0066CC;
$btn_primary_fs: 16px;
$btn_primary_fw: 500;

$btn_disable_primary_fc: #8A8A8A;
$btn_disable_primary_bg: #EFEFEF;

$btn_secondary_disable_bdr: #BABABA;
$btn_secondary_disable_bg:  #EFEFEF;
$btn_secondary_disable_fc:  #8A8A8A;
$btn_secondary_disable_fs: 16px;
$btn_secondary_disable_fw: 500;

//Breadcrumb
$breadcrumb_fs: 12px;
$breadcrumb_active_fc: #737373;
$breadcrumb_active_fw: 400;
$breadcrumb_link_fc: #0066CC;
$breadcrumb_link_fw: 500;

//Icon
$search_cancel_icon_color: #737373;

//checkbox
$checkbox_color: #FFFFFF;
$checkbox_lable_fc: #333333;
$checkbox_border: #737373;
$checkbox_fw: 300;
$checkbox_fs: 16px;

$checkbox_disable_color: #EFEFEF;
$checkbox_disable_lable_fc: #8A8A8A;
$checkbox_disable_border: #BABABA;

//Form Field
$form_field_fs: 16px;
$form_field_fw: 400;
$form_field_fc: #333333;
$form_error_color: #CC0033;
$form_field_disable_bg: #FAFAFA;
$form_field_lable_disable_fc: #8A8A8A;
$form_field_lable_disable_fs: 12px;
$form_field_lable_disable_fw: 400;
$form_field_lable_empty_fc: #737373;

