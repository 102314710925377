/* You can add global styles to this file, and also import other style files */
@import '../src/assets/scss/variable';
@import './assets/scss/reset';

* {
    box-sizing: border-box;
}

html,
body {
    height : 100%;
    margin : 0;
    padding: 0;
}

body {
    margin          : 0;
    font-family     : $font-family;
    background-color: $bg;
    font-size       : $font-size;
    font-weight     : $font-weight;
}

.mat-body,
.mat-body-1,
.mat-typography {
    background-color: $bg;
}

.cont-fluid {
    width         : auto;
    margin        : auto 30px;
    margin-top    : 20px;
    padding-bottom: 30px;
    // border: 1px solid red;
}

.project-sub-title {
    font-size  : $font-size-18;
    color      : $dark-black;
    font-weight: $tbl_header_fw;
    font-family: $font-family;
}

//Table 
.cmn_tbl {

    .mat-card {
        padding: 0px;

        .mat-card-header-text {
            margin: 0;
        }

        .mat-card-header {
            justify-content: space-between;
            align-items    : center;
            padding        : 20px 20px;

            .mat-card-title {
                font-size  : $tbl_title_fs;
                color      : $tbl_title_fc;
                font-weight: $tbl_title_fw;
            }

            .search-btn-div {
                display    : flex;
                align-items: center;
                gap        : 20px;
            }

            .mat-form-field {
                border-radius: 5px;
                height       : 35px;

                .mat-input-element {
                    padding-left: 5px;
                }

                .mat-icon {
                    padding: 2px;
                    color  : $search_cancel_icon_color;
                }
            }

            .mat-form-field-appearance-fill .mat-form-field-flex {
                height          : 45px !important;
                padding         : 0px 8px 0px 10px;
                align-items     : center;
                background-color: $tbl_header_bg;
                width           : 250px;
            }

            .mat-form-field-appearance-fill.mat-focused .mat-form-field-flex {
                border: 2px solid $blue;

                .mat-icon-search {
                    color: $blue;
                }
            }
        }

        .mat-card-content {
            margin-bottom: 0px !important;
        }

        .mat-table {
            width: 100%;

            .mat-header-row,
            .mat-sort-header-content,
            .action-header {
                font-size       : $tbl_header_fs;
                font-weight     : $tbl_header_fw;
                color           : $tbl_header_fc;
                background-color: $tbl_header_bg;
            }

            .mat-cell {
                font-size  : $tbl_cont_fs;
                font-weight: $tbl_cont_fw;
                color      : $tbl_cont_fc;
                font-family: $font-family;
                gap        : 5px;
            }

            th.mat-header-cell,
            td.mat-cell,
            td.mat-footer-cell {
                padding: 0;
                border : none;
            }

            th.radio-btn-headercell,
            td.radio-btn-cell {
                width       : 32px;
                padding-left: 15px;
            }

            .name-header,
            .name-cell {
                min-width: 350px;
            }

            .orgRole-header,
            .orgRole-cell {
                min-width: 200px;
            }

            .action-header,
            .action-cell {
                justify-content: center;
            }

            .mat-stroked-button {
                padding      : 0 24px;
                border-radius: 18px;
                // width: 100px;
                border       : 1px solid $tbl_roundbtn_bdr;
                background   : inherit;
                color        : $tbl_roundbtn_fc;

                font: {
                    size  : $tbl_roundbtn_fs;
                    weight: $tbl_roundbtn_fw;
                    family: $font-family;
                }
            }

            .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
                border-color: $blue;
            }

            .mat-radio-button.mat-accent .mat-radio-inner-circle {
                background-color: $blue;
            }
        }

        .mat-card-footer {
            margin         : 0 15px;
            padding-bottom : 5px;
            
            .mat-stroked-button{
                float: left;
                margin-top: 12px;
            }

            .mat-paginator-page-size-select {
                margin: 0px 4px 15px 4px;
            }

            .mat-paginator-page-size {
                align-items: center;
            }
        }
    }

}

//Breadcrumb
.breadcrumb {
    line-height  : 24px;
    margin-bottom: 0;
    background   : transparent;

    li {
        font-size     : $breadcrumb_fs;
        display       : inline-block;

        a {
            font-size    : $breadcrumb_fs;
            color        : $breadcrumb_link_fc !important;
            padding-right: 5px;
            padding-left : 5px;
            cursor       : pointer;

            &:hover {
                text-decoration: none;
            }
        }

        .mat-icon {
            color: $breadcrumb_active_fc;
        }
    }
}

.breadcrumb-item.active {
    margin-left: 5px;
    color      : $breadcrumb_active_fc;
    font-weight: $breadcrumb_active_fw;
    font-size  : $breadcrumb_fs;
}

//Button
.mat-raised-button {
    background-color: $btn_primary_bg;
    font-size       : $btn_primary_fs;
    font-weight     : $btn_primary_fw;
    font-family     : $font-family;
    color           : $btn_primary_fc;
    border-radius   : 4px;
    padding         : 0 24px;
}

.mat-raised-button:disabled {
    background: $btn_disable_primary_bg;
    color     : $btn_disable_primary_fc;
}

.mat-stroked-button {
    background-color: $btn_secondary_bg;
    font-size       : $btn_secondary_fs;
    font-weight     : $btn_secondary_fw;
    font-family     : $font-family;
    color           : $btn_secondary_fc;
    border          : 1px solid $btn_secondary_bdr;
    border-radius   : 4px;
    padding         : 0 24px;
}

.mat-stroked-button:disabled {
    border       : 1px solid $btn_secondary_disable_bdr;
    border-radius: 4px;
    background   : $btn_secondary_disable_bg;
    color        : $btn_secondary_disable_fc;

    font: {
        size  : $btn_secondary_disable_fs;
        weight: $btn_secondary_disable_fw;
        family: $font-family;
    }
}

//Dialog box
.mat-dialog-container {
    padding : unset !important;
    overflow: unset !important;

    mat-card {
        box-shadow: none !important;

        .mat-card-header-text {
            margin: 0px 0px 7px 0px;
        }

        .mat-card-title {
            font-size  : $tbl_title_fs;
            font-weight: $tbl_header_fw;
            font-family: $font-family;
            color      : $black;
        }

        mat-card-content {
            margin-bottom: 0px;
        }

        mat-error {
            padding-top: 8px;
           // margin-left: 11px;
           width: 400px;
            color      : $form_error_color;
            font-size: 13px;
        }

        mat-card-footer {
            padding: 10px;
        }

        mat-checkbox {
            padding-right: 80px;
        }
    }
}

//Text Area
textarea {
    resize: none !important;
}

//Radio button
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $blue;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $blue;
}

//Form field
.mat-form-field {
    border-radius: 4px;
    height       : 35px;

    .mat-input-element {
        font-size  : $form_field_fs;
        font-weight: $form_field_fw;
        color      : $form_field_fc;
        font-family: $font-family;
    }

    .mat-input-element:disabled {
        color        : $form_field_lable_disable_fc;
        text-overflow: ellipsis;
    }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    height          : 45px !important;
    padding         : unset 8px 12px 10px;
    align-items     : center;
    background-color: $tbl_header_bg;
    width           : 280px;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    background-color: $form_field_disable_bg;
}

.mat-dialog-actions {
    float        : right;
    padding-right: 15px !important;
    margin-bottom: unset !important;
}

.mat-form-field-empty.mat-form-field-label {
    color      : $form_field_lable_empty_fc;
    font-size  : $form_field_fs;
    font-weight: $form_field_fw;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $blue;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: $form_error_color;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
    color: $form_error_color !important;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
    color      : $form_field_lable_disable_fc;
    font-size  : $form_field_lable_disable_fs;
    font-family: $font-family;
    font-weight: $form_field_lable_disable_fw;
}

.mat-checkbox-layout .mat-checkbox-label {
    font-size  : $checkbox_fs;
    font-weight: $checkbox_fw;
    color      : $checkbox_lable_fc;
}

.mat-checkbox-disabled .mat-checkbox-label {
    color: $checkbox_disable_lable_fc;
}

.mat-checkbox-frame {
    background-color: $checkbox_color;
    border          : 2px solid $checkbox_border;
    height          : 16px;
    width           : 16px;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border          : 2px solid $checkbox_disable_border;
    background-color: $checkbox_disable_color;

}

.mat-checkbox-disabled.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    border          : 2px solid $checkbox_disable_border;
    background-color: $checkbox_disable_border;
}

.mat-checkbox .mat-checkbox-ripple {
    display: none;
}

//Toast Message
.toast-warning {
    background-image: url(./assets/images/warning.svg) !important;
    background-color: #FFC414 !important;
    border          : 2px solid #FFC414 !important;
}

.toast-success {
    background-image: url(./assets/images/success.svg) !important;
    background-color: #00875A !important;
    border          : 2px solid #00875A !important;
}

.toast-error {
    background-image: url(./assets/images/warning.svg) !important;
    background-color: #CC0033 !important;
    border          : 2px solid #CC0033 !important;
}

.toast-message {
    word-wrap  : break-word !important;
    padding    : 10px 0px 10px 10px !important;
    background : white !important;
    color      : black !important;
    min-height : 60px;
    align-items: center;
    display    : flex;
}

.toast-close-button {
    padding   : 20px !important;
    cursor    : pointer !important;
    background: #fff !important;
    border    : 0 !important;
    top       : 0 !important;
    right     : 0 !important;
    color     : black !important;

    &:hover {
        opacity: 0.8 !important;
    }
}

.toast-container .ngx-toastr {
    padding        : 0 0 0 65px !important;
    border-radius  : 4px !important;
    background-size: 35px !important;
    width          : 400px !important;
    color          : black !important;
}

.profileMenu {
    max-width: 400px !important;
    ul {
        margin : 0;
        padding: 20px 20px 15px 20px;

        li {
            margin         : 0;
            padding        : 5px 0;
            list-style-type: none;
            text-align     : center;

            &:first-child {
                margin-bottom: 20px;

                span {
                    font-size: 24px;
                }
            }

            &:nth-child(2) {
                text-transform: capitalize;
            }
        }

    }

    .bt {
        display        : flex;
        justify-content: end;
        padding-right  : 20px;
        margin-top     : 5px;
        margin-bottom  : 5px;
    }
}

.mat-menu-panel{
    .mat-menu-item{
        padding: 0 24px !important;
    }
}

//Form field
.mat-form-field {
    border-radius: 4px;
    height       : 35px;

    .mat-input-element {
        font-size  : $form_field_fs;
        font-weight: $form_field_fw;
        color      : $form_field_fc;
        font-family: $font-family;
    }

    /* .mat-icon {
       padding: 2px;
       color: $grey;
   } */
}

.mat-form-field {
    border-radius: 4px;
    height       : 35px;

    .mat-input-element {
        padding-top: 5px;
        font-size  : $form_field_fs;
        font-weight: $form_field_fw;
        color      : $form_field_fc;
        font-family: $font-family;

    }

}

.mat-form-field-appearance-fill .mat-form-field-flex {
    height          : 45px !important;
    padding         : unset 8px 12px 10px;
    align-items     : center;
    background-color: $tbl_header_bg;
    width           : 280px;
}

.mat-form-field-label,
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    padding-top: 6px;
}


/* Modal - Table */
.mat-dialog-container {
    .mat-table {
        margin-left : -15px;
        margin-right: -15px;
    }
}

.mat-tab-group.mat-primary .mat-ink-bar {
    background-color: $blue !important;
}

.mat-tab-label-active  {
    color: $blue !important;
    opacity: 1 !important;
}

.mat-menu-panel {
    button img {
        margin-right: 20px;
        width       : 28px;
    }
}

.header {
    .right {
        .material-icons {
            font-size: 28px;
        }
    }
}

.cont {
    .header .left {
        img {
            overflow: inherit !important;
            height  : 40px;
        }
    }
}

.no-data {
    text-align    : center;
    margin-top    : 40px;
    font-size     : x-large;
    color         : $grey1;
    padding-bottom: 40px;
}

.empty-dta {
    padding-top: 40px;
    height     : 25vh;
}

.loading-user-spinner .mat-progress-spinner {
    margin-left: 46% !important;
    top        : 40% !important;
}
.mat-select-panel {
    margin-top: 40px !important;
}

.loading-modal-spinner {
    z-index   : 1011;
    background: $white;
    opacity   : 0.8;
    position  : absolute;
    width     : 100%;
    height    : 100%;
    top       : 0px;
    left      : 0px;
    bottom    : 0px;
 
    .spinner-class {
        width : 44px;
        height: 44px;
    }
 }
 .loading-modal-spinner .mat-progress-spinner {
    margin-left: 50%;
    top        : 50%;
    left       : -22px;
    margin-top : -22px;
 }

 .details-page{
    .header-grid-list{
        .mat-grid-list {
            background: $white;
            box-shadow: 0px 0px 10px #666;
        }
    
        .mat-grid-tile-content {
            justify-content: left;
            margin-left    : 20px;
            font-weight    : 900;
            font-size      : 16px;
        }
    
        .mat-grid-tile-content .data-catalog{
            margin-top: -20px;
        }
        .header-close{
            .mat-grid-tile-content{
                gap: 10px;
                h3{
                    margin: 0px !important;
                    color: $black;                   
                }
            }
        }
    }
    
    .mat-card{
        .mat-card-title {
            font-size  : $tbl_title_fs;
            font-weight: $tbl_header_fw;
            font-family: $font-family;
            color      : $black;
            padding    : 25px 0px 0px 25px;
        }
        
    h4{
        color: $black;
        font-size: 16px;
        font-weight: 400;
    }
    p{
        color: $grey1;
        margin-bottom: 5px;
        font-weight: 400;
    }    
    .desc-div{
        .mat-grid-tile-content{
            align-items: flex-start !important;
            overflow-y: auto;
        }
    }
    .mat-grid-list{
        margin: 0px 25px 10px 25px;
    }
    .mat-grid-tile-content{       
        justify-content: flex-start !important;       
}
}
}

/*scroll bar*/
::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $light-grey;
}
.invite-popup-card{
    .mat-grid-tile-content{
        justify-content: flex-start;
        margin-left: 15px;
        
    }
}

//Audit results module styles
.auditstart-results{
    .mat-grid-tile-content{
        justify-content: flex-start;
        margin-left: 25px;
    }
}
.audit-results{
    .audit-result-form{        
            .mat-form-field-appearance-fill .mat-form-field-flex{
                min-width: 65%;
                height: auto !important;
                /* max-height: 150px !important; */
                padding-top: 0px;
              }                
              .comments-formfield .mat-form-field-required-marker{
                font-size: 20px;
                color: inherit;
              }
              .email-chip-formfield .mat-form-field-underline{
                width: 65%;  
                bottom: 0;      
              }    
              .email-chip-formfield .mat-form-field-wrapper{
                padding-bottom: 0px;
            }
            .email-chip-formfield .mat-chip-list-wrapper{
                /* max-height: 135px;
                overflow: auto;
                margin-top: 0px; */
                /* flex-wrap: unset !important; */
                /* height: 100px;
                overflow-x: scroll; */
              }
             .mat-error{
                padding-top: unset !important;                
            }
        }
}
